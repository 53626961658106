.App {
  text-align: center;
}

/* *{
  overflow-y: hidden;
} */

 /* .video {
 height:auto;
  width: 100%;
}  */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-l {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  flex:1
}
.vide{
 
   right: 0; bottom: 100;
    min-width: 100%; min-height: 100%;
    width: auto; height: auto; z-index: -100;

    background-size: cover;
    width: 100%;
    height: auto;
    /* position: fixed; */
    top: 0;
    left: 0;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
     /* margin-top: -80px;  */

}
.vide{
 
  right: 0; bottom: 100;
   min-width: 100%; min-height: 100%;
   width: auto; height: auto; z-index: -100;

   background-size: cover;
   width: 100%;
   height: auto;
   /* position: fixed; */
   top: 0;
   left: 0;
   height: 100%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
    /* margin-top: -80px;  */

}
.videSmall{
 
  right: 0; bottom: 100;
  min-width: 100%; min-height: 100%;
  width: auto; height: auto; z-index: -100;

  background-size: cover;
  width: 100%;
  height: auto;
  /* position: fixed; */
  top: 0;
  left: 0;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -80px;

}
.backgroundLoginBigcss {
  background-image: url("./image/login-back.jpg");
  background-size:cover;



  height: 100vh;
  
}
.backgroundLoginMedium{
  background-image: url("./image/login-back.jpg");
  background-size:cover;



  height: 119vh;
}

.backgroundLogin {
  background-image: url("./image/login-back.jpg");
  background-size:cover;


  /* background: cover; */
  height: 124vh;
  
}
/* .wrapper         {width:100%;height:90%;margin:0 auto;}
  .h_iframe        {position:relative;}
  .h_iframe .ratio {display:block;width:100%;height:auto;}
  .h_iframe iframe {position:absolute;top:0;left:0;width:100%; height:100%;} */
  img.bg {
    width: 100%;
  
    position: relative;
    top: 0;
    left: 0;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

  }
  
  /* @media screen and (max-width: 1024px){
    img.bg {
      left: 50%;
      margin-left: -512px; }
  }
   */
  /* iframe{
    height: 100vh;
    width: 100%;
  position: fixed; right: 0; bottom: 100;
    min-width: 100%; min-height: 100%;
    width: auto; height: auto; z-index: -100;
    background-position: 50% 80%;
    background-size: cover;
     margin-top: -80px; 
  } */


::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */

  /* .zone1DesktopVideo {
    position: absolute;
    top: 1.5%;
    left: 53.5%;;
    height: 100%;
    width: 30%;
   
    color: white;
   
  } */


  .background {
    background-image: url("./image/back.jpg");
    background-size:cover;


    background: cover;
    height: 100vh;
    /* margin-top: -140px; */
 }
 
.backgroundTest {
  background-image: url("./image/data.jpeg");
  background-size:cover;


 
  height: 100vh;
  /* margin-top: -140px; */
}
.top30{
  position: absolute;
  top: 47%;
}

.top50{
  position: absolute;
  top: 65%;
}

.top30mac{
  position: absolute;
  top: 42%;
}

.top50mac{
  position: absolute;
  top: 56%;
}
 div>.w-12:hover {
  background: black;
  opacity: 0.5;
  color:white;
  border: 1px solid #adadad;
}
/* div>.w-15:hover {
  background: black;
  opacity: 0.5;
  color:white;
  border: 1px solid #adadad;
} */

/* 
*{
  overflow-y: hidden;
} */

.borderZero
{
  border: 0px;
}

/* 
img {
  max-width: 100%;
  height: auto;
} */


.hoverName:hover{
color: white;
background-color: black;
opacity: 0.7;
}

.transparent{
  color: transparent;
}
 .f1{
     position: absolute;
     top: 62%;
     left: 10%;
      
  
 }

 .dropdown {
  z-index: 1;
}

.dropdown-content {
  display: none;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
} 




.liveatten{
 
  background-image: url("../src/image/liveatten.jpg");
  height: 125vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: scroll; 
}
.liveattenBig{
  
  background-image: url("../src/image/liveatten.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* position: fixed;  */
  width: 100%; 
     height: 100vh; 
   
 left: 0px; 
    top: 0px;    
}
.liveattenMedium{

  background-image: url("../src/image/liveatten.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* position: fixed;  */
  width: 100%; 
     height: 120vh; 
   
 left: 0px; 
    top: 0px;   
}
.api{
  
  background-image: url("../src/image/lb.jpg");
  height: 125vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: scroll;
}
.apibig{
  
  background-image: url("../src/image/lb.jpg");
  height: 100vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: scroll;
}
.apiMedium{
  
  background-image: url("../src/image/lb.jpg");
  height: 120vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: scroll;
}
.scoll {
  overflow: auto;
  max-width: 800px;
  max-height: 600px;
}



.box{
  
  background:linear-gradient(#202F3D,transparent);
  background-color:#0D0E13 /*this your primary color*/
}



/* nav ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
} */

.nav {
  display: inline-block;
  margin: 2rem auto 0;
  /* background: #0d2035; */
  color: #fff;
  /* text-align: left; */
}
.nav a {
  /* display: block; */
  /* padding: 0 16px; */
  /* line-height: inherit; */
  cursor: pointer;
}

.nav__menu {
  line-height: 45px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav__menu-item {
  display: inline-block;
  position: relative;
}
.nav:hover {
  /* background-color: white; */
}
.nav__menu-item:hover .nav__submenu {
  display: block;
}

.nav__submenu {
  font-weight: 300;
  /* text-transform: none;
  display: none;
  position: absolute;
  width: 250px; */
  background-color: white;
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
  right: 20%;
  width: 300px;
  /* width: 250
px
; */
  /* background-color: white; */
}

.nav__submenu-item:hover {
  background: rgba(0, 0, 0, 0.1);
}


.img-wrapper {
  overflow: auto; /* adds scrollbars */
  height: 400px;
  background-color: transparent;
  position: relative;
}

.img-wrapper > img {
  height: 200%; /* probably looks neater if auto */
  width: 200%; /* double width image to show only first quarter */
  vertical-align: bottom; /* moves image to true text bottom */
}


.fade-out {
	animation: fadeOut 2s;
  	opacity: 0;
}

@keyframes fadeOut {
  from {
  	opacity: 1;
  }
  to {
 	opacity: 0.4;
  }
}




.btn {

  background-color: rgb(118, 181, 82);
  /* transition: all .3s ease; */
 
}

/* @keyframes fade-in {
  to {
    top: 25%;
  }
} */

/* Non Relevant  */

/* div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
 */






 /* .bg {

  background-image: url("./LOGIN-PAGE-1-without-anything.png");

  
  height: 100vh; 

 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */
.gameCol{
  height: 400px;
  width: 400px;
}
.gpadding {
  padding: 1rem 9rem 0rem 9rem !important;
}

.row,.col{
  margin: 0px;
  padding: 0px;
}

/* .centerDiv{
  position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
} */
.centerDiv {
  position: absolute;

  top: 20%;
  left: 50%;

}
.whiteborder{
  border: 6px solid white;
}
.bb{
  border: 2px solid transparent !important;
  border-bottom-color: black !important;
}

.purple{
  color: #0A4B81;
  font-weight: 800;
}


.pupleButton{

  background: #0A4B81;
}
.white{
  color: white;
}

.heading{

  font-size: 18px;
}

.rounded {
  border-radius: 1.25rem!important;
  
}

.gameColbutton{
width: 400px;
}

.tr{
  text-align: right;
}

input{
  height:26px
}

.mrg{
  margin-top: 140px;
}

.emg{
  margin-top: 15px;
}

.parent{
position: relative;
}
.over-img{
  position: absolute;

}


.imgp{
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}
.backimgphoto { 




  /*      
    background: url('./photo-jacket-back.jpg') no-repeat;
      
    width: 100%;
    height:100vh;
    
   
    
    background-size:cover; */
  
  
    background: url('./image/photo\ ops\ BG-min.jpg');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: fixed;
    background-repeat: no-repeat;
 height: 128vh;
    
  
  }

  .backimgphoto2 { 
    
      background: url('./image/p2.jpg') ;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
          
    }
  
  .menuname{
font-size: 12px;
font-weight: bold;

color:white;
text-transform: uppercase;
  }
  .footer {
    
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
 }

 .down{
   margin: 5px;
   height: 35px !important;
   cursor: pointer;
 }
 a{
   text-decoration: none;
 }

 a:hover,p:hover{
   color: #0C4C81;
 }

 .btn3{
background-color: #F2911C;
padding: 10px 30px 10px 30px;
font-size: 18px;
 }

.imglo{

 height: 120px;
}

.mrg1{

  margin-top: -280px;
   
}

.mrg2{

  margin-top: -710px;
  margin-left: 62rem;
}



.cardImg { 
    
  background: url('./image/bcard.jpg') ;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position:'center center';
    background-repeat: no-repeat;
      
}



.welcomeMsj { 
    
  background: url('./image/welcome.jpg') ;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position:'center center';
    background-repeat: no-repeat;
      
}


/* .popupVideo {
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  }
 */



 .dropdown-menu {
  width: 300px;
}

.dropdown-menu {
  position: absolute;
  top: 10%;
  /* left: 1%; */
  z-index: 1000;
  right: 80%;
  display: none;
  /* float: left; */
  min-width: 10rem
;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem
;
}
.dropdown:hover>.dropdown-menu {
  display: block;
   background-image: url("./image/welcome.jpg");
  background-position: bottom;
 margin-top: -2.625rem;
  background-repeat: no-repeat;
  background-size: cover;
  border: 5px solid white;
  margin-left: 30%;

 
}

.dropdown-menu.show{
  background-image: url("./image/welcome.jpg");
  border: 5px solid white;
  margin-top: -1.875rem;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;}

  a.dropdown-item:hover, a.dropdown-item{
    color:black;
   padding-left: 1.25rem;
    font-size: 14px;
    background: transparent;
    font-weight: 600;
  }

  .know-center-padding{
  padding: 0px 400px !important;


}

.dropKnow{
  font-size:10px;
  border-color:transparent !important;
  background-color:transparent !important ;
  margin-left: 1rem;
  margin-top: 1.5rem;
  font-size: .8rem !important;
  color:black !important;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-image: url("./image/welcome.jpg");
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  
  display: block;
}

.dropdown-content a:hover {background-image:  url("./image/welcome.jpg");}

.dropdown:hover .dropdown-content {display: block;}






.fixTableHead {
  overflow-y: auto;
  height: 60px;
  
}
.fixTableHead2 {
  position: sticky;
  top: 0;
}


/* th {
  background: #ABDD93;
} */