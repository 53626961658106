* {
    scroll-behavior: smooth;
    font-family: Montserrat;
  }
  
  body {
    height: 100vh;
    background-color: #e8e9ef;
  }
  
  /* iframe {
    width: 100% !important;
  } */
  
  .datadigest::-webkit-scrollbar {
    display: none;
  }
  
  
  
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }
  
  .object-cover {
    object-fit: cover;
  }
  
  .object-contain {
    object-fit: contain;
  }
  .object-top {
    object-position: 0% 0%;
  }
  .object-top2 {
    object-position: 0% 20%;
  }
  
  /* ==================== */
  /* === CALENDAR CSS === */
  
  /* [PERIOD SELECTOR] */
  #cal-date {
    /* margin: 10px 0; */
  }
  #cal-mth,
  #cal-yr,
  #cal-set {
    padding: 10px;
    font-size: 1.2em;
    border: 0;
    background: #ea4c4c;
    color: #fff;
  }
  
  /* [CALENDAR] */
  #calendar {
    width: 100%;
    border-collapse: collapse;
  }
  #calendar tr.head td {
    font-size: 8px;
    font-weight: 500;
    text-transform: uppercase;
    color: #727682;
    padding: 17px 0px;
    text-align: center;
  }
  #calendar tr.head {
    background: linear-gradient(to right, #fff, #f0f1f2, #f0f1f2, #fff);
  }
  
  #calendar tr.day td {
    font-size: 12px;
    color: #141b2f;
    border: 1px solid #ddd;
    width: 14.28%;
    height: 50px;
    /* padding: 15px 5px; */
    vertical-align: top;
    padding: 2px;
  }
  
  /* #calendar tr.day td:hover {
   background: #fff9e4;
  } */
  
  #calendar tr td.blank {
    background: #f5f5f5;
  }
  
  #calendar .evt {
    margin-top: 5px;
    font-size: 0.8em;
    overflow: hidden;
  }
  
  td:has(.evt) {
    background: #a70e13;
  }
  
  #calendar .schedule-T20 {
    color: #35a863;
  }
  #calendar .schedule-ODI {
    color: #d44030;
  }
  #calendar .schedule-Test {
    color: #4a90e2;
    background: #d4e5f7;
  }
  
  .cal-Test {
    background: #d4e5f7;
  }
  .cal-ODI {
    background: #f5d4d4;
  }
  .cal-T20 {
    background: #cfeada;
  }
  
  /* [ADD/EDIT EVENT] */
  #cal-event form {
    padding: 15px;
    margin-top: 20px;
    background: #f5f5f5;
    border: 1px solid #ddd;
  }
  #cal-event h1 {
    color: #333;
    padding: 0;
    margin: 0;
  }
  #evt-date {
    color: #999;
    margin: 10px 0;
  }
  #cal-event textarea {
    display: block;
    box-sizing: padding-box;
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    resize: none;
  }
  #cal-event input[type='button'],
  #cal-event input[type='submit'] {
    padding: 10px;
    margin: 5px;
    font-size: 1.2em;
    border: 0;
    background: #ea4c4c;
    color: #fff;
  }
  
  .player-projection .card-active {
    margin-top: 16px;
    height: 56px;
    overflow: hidden;
    background-color: white;
    border-radius: 2px;
    border: 1px solid #a70e13;
    outline: none !important;
    transform: scale(1.2);
  }
  
  .player-projection .card {
    /* // width: 14.2%; */
    /* // height: 12.2%; */
    height: 56px;
    overflow: hidden;
    /* // border-radius: 2px; */
    background-color: #d8d8d8;
    outline: none !important;
  }
  
  /* .widget {
   height: 100vh;
   width: 200px;
   margin-left: auto;
   margin-right: auto;
  } */
  
  .progress-bar {
    fill: rgb(233, 96, 5);
  }
  
  .progress-bar-bg {
    fill: #c7c2c2;
  }
  
  .progress-label {
    fill: rgb(41, 40, 40);
    font-family: 'oswald';
    font-size: 14px;
    font-weight: 500;
    text-anchor: middle;
    dominant-baseline: central;
  }
  .rorate-div {
    font-size: 10px;
    font-weight: 600;
    color: #fff;
  }
  
  .jss1 {
    fill: currentColor;
  
    height: 1em;
  
    font-size: 24px;
  }
  
  .cric-card {
    background: linear-gradient(135deg, #eeeeee 0%, #ffffff 100%);
  }
  
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1.6rem;
  }
  
  /* .swiper-slide {
   width: unset !important;
  } */
  
  .vertical-text {
    transform: rotate(-90deg);
    /* align-items: center; */
  }
  .live-text {
    align-items: center;
    width: 4%;
    border-radius: 6px 0 0 6px;
  }
  .playerImage {
    margin-right: 0px !important;
    width: 0px !important;
  }
  
  .sticky {
    position: sticky;
  }
  
  @media screen and (min-width: 30em) {
    .top-3-ns {
      top: 3rem;
    }
  }
  @media screen and (max-width: 30em) {
    .scorecard-up-arrow-triangle:before,
    .scorecard-up-arrow-triangle:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 1.1875rem;
      z-index: 9999;
      border: 0.6875rem solid transparent;
      border-bottom-color: #172132;
    }
  
    .scorecard-up-arrow-triangle:after {
      left: 1.1875rem;
      z-index: 9999;
      border: 0.6875rem solid transparent;
      border-bottom-color: #172132;
    }
  }
  @media screen and (min-width: 30em) {
    .bottom-4-ns {
      bottom: 4rem;
    }
  }
  .card-flip {
    transform: rotateY(180deg);
    margin-top: 4px;
  }
  .poll {
    transition: width 2s;
  }
  .multi-line-truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
  .triangle {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid rgb(53, 62, 89);
  }
  
  .flip-horizontally {
    transform: scaleX(-1);
  }
  
  .border-input,
  .border-input:focus {
    border-color: transparent;
    border-bottom-color: gray;
    outline: none;
  }
  
  
  
  
  .border-inputcode,
  .border-inputcode:focus {
    border-color: transparent;
    border-bottom-color: transparent;
    outline: none;
  }
   .dropdown {
    z-index: 1;
  }
  
  .dropdown-content {
    display: none;
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  } 
  
  .scorecard-up-arrow-triangle {
    position: relative;
  }
  
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    /* fallback */
    /* max-height: 32px;   */
    /* fallback */
    /* -webkit-line-clamp: 2;  */
    /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .h-01{
    height: 1px;
  }
  
  a {
    text-decoration: none;
    color: black;
  }
  
  
  p  a{
    text-decoration: underline;
      color: blue;
  }
  
  
  .w-18{
    width: 18%;
  }
  
  .right-05{
    right:0.5rem
  }
  
  .smallRetain { display: flex; justify-content: center; align-items: center; width: 15px; height: 15px; border-radius: 50%; }
  
  
  
  
  .iplbanner { background:linear-gradient( to left, #933431, #bb3e3ed1 );}
  
/*   
  .iplbannerback {
    background-image: url("../../public/static/pngs/c.png");
    background: linear-gradient( to left, #933531, #BB3E3E );
    
  }
   */
  .rightArr {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #a70e13;
  }
  
  .StepProgress {
    position: relative;
    padding-left: 45px;
    list-style: none;
    
    
    
   
      
    }
    
    strong {
      display: block;
    }
  
  
  
  .timeline:before{
    content:'';
    position:absolute;
   left:0.5rem;
   right:0;
  
    width:1px;
    height:100%;
    background-color: #a70e13;
  }
  
  .timeline{
    position:relative;
  }
  
  *{
    -webkit-touch-callout: none;
    /* -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-tap-highlight-color: transparent;
  }
  
  .scroll-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
  
  .section-scroll {
    height: 100vh;
    scroll-snap-align: center;
  }
  
  .yellow-frc
  { 
  color:#EEBA04
  
  }
  .bg-frc-yellow{
    background-color:#EEBA04 ;
  }
  
  .choose-Frc{
    color: #1A1A1A
  }
  
  .b--frc-yellow{
  border-color:#EEBA04 ;
  }
  
  
  .min-vh-10{
    min-height: 10vh;
  }
  /* .cricbattl
  e {
    background-image: url("../../public/static/pngs/cricbattle_background.png");
    height:100vh; */
  
    /* background: linear-gradient( to left, #933531, #BB3E3E ); */
    
  /* }../../public/static/pngs/cricbattle_background.png */
  
  /* .ballsMini {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  } */
  
  
  .w77{
    width: 7rem;
    }
    .h77{
      height: 7rem;
    }
    
    .wheel{
      border-color:white
    }
    .red_dougout{
  background-color: #EF0505;
    }
  
    .popred{
      background-color: #B70000;
    }
  
    .h55 { height: 5.5rem; }
  
    .w55
    {
      width: 5.5rem;
    }
  
    .scores{
    background-color:   #FFE8D9;
    }
  
    .bw-01
    {
      border-width:0.05rem;
    }
  
  
  
    .w99 { height: 9rem; }
  
    .w99
    {
      width: 9rem;
    }
  